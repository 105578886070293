.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  margin: 12px;
  gap: 0;
}
.dashboard-table td {
  padding: 4px 8px;
}
.dashboard-table .monospace {
  font-family: "Fira Code", "Fira Sans Mono", "Roboto Mono", "Droid Sans Mono",
    "Consolas", monospace;
}

.dashboard-table thead th {
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  padding-left: 1em;
  padding-right: 1em;
}
.dashboard-table thead th:hover {
  color: var(--dark-evergreen);
}

.dashboard-table tbody tr:hover td,
.dashboard-table tbody tr:hover th {
  background: var(--softened-evergreen);
}

.dashboard-table tbody tr.suspended td,
.dashboard-table tbody tr.suspended th {
  background: var(--softened-gold);
}
.dashboard-table tbody tr.abandoned td,
.dashboard-table tbody tr.abandoned th {
  background: var(--softened-peach);
}
