/* Color Palette */

:root {
  /* ==== base named colors ==== */
  --evergreen: hsl(155, 76%, 50%); /* #1fe191 */
  --softened-evergreen: hsl(155, 76%, 90%); /* #d2f9e9 */
  --middle-evergreen: hsl(155, 74%, 41%); /* #1bb575 */
  --dark-evergreen: hsl(155, 72%, 31%); /* #168959 */
  --pure-white: hsl(0, 0%, 100%); /* #ffffff */
  --abyss: hsl(0, 0%, 13%); /* #222222 */
  --salmon-red: hsl(11, 72%, 64%); /* #e57962 */
  --sky-blue: hsl(184, 76%, 50%); /* #1fd5e1 */
  --semitranslucent-white: hsla(0, 0%, 100%, 0.8);
  --translucent-white: hsla(0, 0%, 100%, 0.5);

  /* ==== colors labeled by roles ==== */

  /* brand */
  --primary-color: var(--evergreen);

  /* fonts */
  --dark-font: var(--abyss);
  --light-font: var(--pure-white);

  /* buttons */
  --lightest-button-color: var(--pure-white);
  --base-button-color: var(--evergreen);
  --transition-button-color: var(--middle-evergreen);
  --darkest-button-color: var(--dark-evergreen);

  /* effects */
  --shadow-bluish: hsla(199, 18%, 40%, 0.2);

  /* PROVIDER PORTAL COLORS */
  --mint-green: hsl(166, 36%, 83%); /* #c5e4dd */
  --dark-mint-green: hsl(180, 8%, 36%); /* #4e5b58; */ 
  --blush-pink: hsl(346, 48%, 61%); /* #cc6681 */
  --peach-cream: hsl(16, 70%, 69%); /* #e59173 */
  --sky-mist: hsl(191, 75%, 58%); /* #39c5e5 */
  --fresh-sprout: hsl(142, 48%, 62%); /* #71cc89 */

  /* TAYLOR COLORS NOT DEFINED IN PALETTE */
  --gold: hsl(43, 65%, 54%);
  --softened-gold: hsl(43, 100%, 88%);
  --softened-peach: hsl(16, 81%, 87%);
  --disabled-gray-background: hsl(0, 0%, 85%);

  /* header (navbar) */
  --header-font: var(--dark-mint-green);
}
