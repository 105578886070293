#highlight_base .highlight-on,
#highlight_base .highlight-on path,
#highlight_base .highlight-on polyline,
#highlight_base .highlight-on g,
#highlight_base .highlight-on rect {
  /* display: unset !important; */
  opacity: 1.0 !important;
  fill-opacity: 1.0 !important;
  stroke-opacity: 0.5 !important;
  mix-blend-mode: multiply;
}

/*
  See AnatomicalModelResponse for notes on how this is applied, it isn't
  *exactly* the opposite of .highlight-on for two distinct reasons.

  Also note that .has-highlight is applied to the root SVG because it needs to
  be visible to the connector lines and legend components, which aren't inside
  of #highlight_base
*/
.has-highlight #highlight_base .highlight-off,
.has-highlight #highlight_base .highlight-off path,
.has-highlight #highlight_base .highlight-off polyline,
.has-highlight #highlight_base .highlight-off g,
.has-highlight #highlight_base .highlight-off rect {
  /* display: unset !important; */
  fill-opacity: 0.25 !important;
  stroke-opacity: 0.1 !important;
}

/*
  Most of the connector line styles are handled by its own component, but this
  requires a combination of logic that's just easier to apply here.
*/
.connector-line {
  /* note this actually ONLY works on Safari, unsupported elsewhere */
  mix-blend-mode: plus-darker;
}
.has-highlight .connector-line.inactive {
  opacity: 0.25 !important;
}

.card.selected {
  background: var(--evergreen);
}

.anatomical-model-legend {
  padding: 10px;
}
.anatomical-model-legend .card {
  margin-bottom: 20px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.anatomical-model-legend .card .card-body {
  padding: 10px 20px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 20px;
  font-weight: bold;
  /* text-overflow: ellipsis; */
}

/* 
#anatomical-root svg {
  outline: greenyellow 1px solid;
} 
*/

svg#base_art rect[stroke="#FF0000"i] {
  display: none;
}

/*
Safari cannot handle painting foreignObjects correctly when some CSS properties
are used -- presumably this is any property that causes the creation of a new
stacking context -- and instead moves their content to an incorrect position.
Worse yet, this often causes the click handler to fail. Thus we must turn off
transforms and replace the opacity call with half-transparent colors.

https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
*/
foreignObject .card {
  transition: unset;
}
foreignObject .card:hover {
  transform: unset;
}
.see-through-unselected .card:not(.selected):not(.has-active-descendant) {
  /* opacity: 0.5; */
  background: rgba(256, 256, 256, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.card.has-active-descendant {
  position: relative;
}
.card.has-active-descendant:after {
  content: "";
  background: radial-gradient(
    circle at center,
    white 33%,
    var(--evergreen) 40%,
    var(--evergreen) 65%,
    transparent 72%
  );
  top: -6px;
  position: absolute;
  width: 16px;
  height: 16px;
  right: -6px;
}
