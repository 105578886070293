.card {
  background-color: var(--pure-white);
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px var(--shadow-bluish);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* max-width: 728px; */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.card-art img {
  width: 100%;
  height: 100%;
  /* necessary for non-square images: */
  object-fit: contain;
}

.card-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-card .card-text-container {
  bottom: 0px;
  height: 50%;
  min-height: 50%;
  margin-top: -20%;
  aspect-ratio: 1 / 0.8;
  max-height: 7.5em; /* 1.5 lh times 5 lines */
}
.stack-card .card-text-container {
  /* height: 100%; */ /* this doesn't do what you want, as parent is flex */
}

.card-title {
  font-weight: bold;
}
.grid-card .card-title {
  max-height: 4.5em; /* 1.5 lh times 3 lines */
}
.card-description {
  max-height: 4.5em; /* 1.5 lh times 3 lines */
}

.card-stack {
  width: 100%;
}

.grid-of-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  /* sanity for this safari growth bug */
  max-width: 100vw;
}
.feedback .grid-of-cards {
  gap: 16px;
}
.feedback .grid-of-cards .card {
  border-radius: 12px;
}

@media (min-width: 744px) {
  .grid-of-cards {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .grid-of-cards.count-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid-of-cards:not(.count-2) .grid-card {
      /* sanity for this safari growth bug */
    max-width: 33vw;
  }

  .feedback .grid-of-cards {
    gap: 32px;
  }
}

.grid-of-cards .grid-card {
  /* width: 100%; */ /* not necessary due to use of fr units, right? */
  aspect-ratio: 1 / 1.6;
  text-align: center;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  position: relative;

    /* sanity for this safari growth bug */
  max-width: 50vw;
}

@media (min-width: 800px) and (max-width: 820px) {
  /* force the demo to comply with sizing */
  .grid-of-cards .grid-card {
    width: 189px !important;
  }
  .grid-of-cards.count-2 .grid-card {
    width: 294px !important;
  } 
}

.grid-card .card-art {
  width: 80%;
  aspect-ratio: 1 / 1;
  margin: 10%;
}

.stack-of-cards {
  display: flex;
  flex-direction: column;
}
.stack-of-cards .stack-card {
  width: 100%;
}
.stack-card {
  display: flex;
  flex-direction: row;
}
.stack-card .card-art {
  aspect-ratio: 1 / 1;
}

@media screen and (max-width: 375px) {
  .stack-of-cards {
    gap: 8px;
  }
  .stack-card {
    min-height: 66px;
  }
  .stack-card .card-art {
    height: 100px;
  }
  .stack-card .card-text-container {
    margin: 8px;
  }
}
@media screen and (min-width: 376px) and (max-width: 743px) {
  .stack-of-cards {
    gap: 12px;
  }
  .stack-card {
    min-height: 72px;
  }
  .stack-card .card-art {
    height: 112px;
  }
  .stack-card .card-text-container {
    margin: 8px;
  }
}
@media screen and (min-width: 744px) and (max-width: 1023px) {
  .stack-of-cards {
    gap: 20px;
  }
  .stack-card {
    min-height: 100px;
  }
  .stack-card .card-art {
    height: 152px;
  }
  .stack-card .card-text-container {
    margin: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .stack-of-cards {
    gap: 24px;
  }
  .stack-card {
    min-height: 112px;
  }
  .stack-card .card-art {
    height: 186px;
  }
  .stack-card .card-text-container {
    margin: 20px;
  }
}
