.beating-heart {
  animation: beat 1s infinite ease-in-out; 
  transform-origin: center; 
  display: inline-block; 
}

@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
