.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

/* ========================= */

.text-center {
  text-align: center;
}

.raised-box {
  max-width: 700px;
  margin: 16px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--pure-white);
}
.raised-box.primary {
  background-color: var(--evergreen);
}

.logo-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  width: 100vw;
  margin-top: 1rem;
}

.logo-bar .logo {
  width: 128px;
  height: 128px;
  padding: 0px;
  border-radius: 24px;
}
.logo-bar .logo img {
  width: 100%;
  height: 100%;
}

/* ========== REPORT TEMP ============ */
/* as we develop the report, these will get their own file */

.report-grid {
  display: grid;
  /* grid-template-columns: [labels] 3fr [values] 4fr [annotations] 3fr [end];
  grid-template-areas: "header header header" "main main main";
  column-gap: 10px;
  row-gap: 5px; */
}
.report-row td {
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.report-value-singular,
.report-value-multi,
.report-entry-text {
  /* grid-column: values / end; */
  padding: 12px;
}
.report-multi-choice p {
  margin: 6px 0px 6px 0px;
}
.report-multi-choice p:first-of-type {
  margin-top: 0;
}
.report-label {
  grid-column: labels;
  /* font-weight: bold; */
  color: var(--dark-font);
  padding: 12px;
  font-weight: 500;
  text-align: left;
}
.report-entry-full {
  grid-column: labels / end;
}
.report-value-measurement .unit-separator {
  opacity: 0.3;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.nonconforming-list {
  font-style: italic;
  margin: 0;
  padding: 0;
}
.nonconforming-list li {
  list-style: none;
}
.nonconforming-list .ncr-choice {
  opacity: 0.75;
}
.user-defined,
.report-computed-cell > :first-child {
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  display: block;
  letter-spacing: 0.5px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease-in-out;
}
.user-defined {
  background-color: var(--softened-evergreen);
  border: 1px solid var(--dark-mint-green);
}
.report-computed-cell > :first-child {
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.user-defined:hover,
.report-computed-cell > :first-child:hover {
  color: white;
  transform: translateY(-2px);
}
.user-defined:hover {
  background-color: var(--dark-mint-green);
}
.report-computed-cell > :first-child:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Tooltip Styles */
.user-defined::after {
  content: "As entered by the patient";
}
.report-computed-cell > :first-child::after {
  content: "Automatically computed value—not provided by patient";
}
.user-defined::after,
.report-computed-cell > :first-child::after {
  position: absolute;
  bottom: 100%; /* Places tooltip above the box */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.user-defined:hover::after,
.report-computed-cell > :first-child:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-4px);
}
/* .report-value-singular .user-defined {
  display: inline-block;
  width: 100%;
} */
