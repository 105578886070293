.edreport > table {
  width: 100%;
}

.edreport table td {
  padding: 5px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.edreport .simple-header {
  font-size: 150%;
  opacity: 0.7;
  padding-right: 33%;
  padding-top: 1em;
}